import React, { useContext } from "react"
import SEO from "../components/seo"
import { graphql } from "gatsby"
import homeStyles from "../components/index.module.scss"
import { Link } from "gatsby"
import SearchBlogPosts from "../components/searchBlogPosts"
import SideBar from "../components/sideBar"
import SearchContext from "../components/context"


export const data = graphql`
  query($skip: Int!, $limit: Int!) {
    allWordpressPost(
      limit: $limit
      skip: $skip
      sort: { fields: date, order: DESC }
    ) {
      edges {
        node {
          slug
          content
          title
          date(formatString: "DD MMMM YYYY", locale: "es")
          featured_media {
            title
            source_url
          }
        }
      }
    }
  }
`
const Home = ({ data, pageContext }) => {
  const context = useContext(SearchContext)
  const searchResults = context ? context.searchResults : ''
  const setSearchResults = context ? context.setSearchResults : ''
  const fireSubmit = context ? context.fireSubmit : ''
  const setFireSubmit = context ? context.setFireSubmit : ''
  const search = context ? context.search : ''
  const setSearch = context ? context.setSearch : ''

  const { currentPage, numPages } = pageContext
  const movePosition = "-3em"

  return (
    <>
      <div
        className={homeStyles.genContainer}
        style={{ backgroundColor: "#fff" }}
      >
        <SEO
          title="Obtén y comparte conocimientos sobre educación para padres con sus hijos"
          description="Unión Unio es un lugar para obtener y compartir conocimientos sobre educación para padres con sus hijos."
          url={currentPage === 1 ? "" : `/${currentPage}`}
        />
        <div className={homeStyles.grid}>
          <div className={homeStyles.sidebarContainer}>
            <SideBar setSearch={setSearch} setFireSubmit={setFireSubmit} />
          </div>
          <div className={homeStyles.content}>
            <h1 className={homeStyles.title}>Lo más nuevo</h1>
            <SearchBlogPosts
              setSearchResults={setSearchResults}
              search={search}
              setSearch={setSearch}
              setFireSubmit={setFireSubmit}
              fireSubmit={fireSubmit}
            />
            <div className={homeStyles.container}>
              <ol className={homeStyles.posts}>
                {searchResults.length > 0
                  ? searchResults.map((edge, i) => {
                      const image = edge.node.featured_media
                        ? edge.node.featured_media.source_url
                        : "https://www.uniomx.com/static/ni%C3%B1os-4db7bcedfa4f813de3752dcf15e04716.png"
                      return i % 2 > 0 ? (
                        <li className={homeStyles.post}>
                          <Link
                            className={homeStyles.link}
                            title={`blog-entry-${edge.node.slug}`}
                            to={`/blog/${edge.node.slug}`}
                          >
                            <div
                              className={homeStyles.textContainer}
                              style={{ marginRight: movePosition }}
                            >
                              <h2
                                dangerouslySetInnerHTML={{
                                  __html:
                                    edge.node.title.length > 50
                                      ? edge.node.title.slice(0, 50) + "..."
                                      : edge.node.title,
                                }}
                                className={homeStyles.blogTitle}
                              ></h2>
                              <p className={homeStyles.blogDate}>
                                {edge.node.date}
                              </p>
                            </div>
                            <div
                              className={homeStyles.blogImage}
                              style={{ backgroundImage: `url(${image})` }}
                            ></div>
                          </Link>
                        </li>
                      ) : (
                        <li className={homeStyles.post}>
                          <Link
                            className={homeStyles.link}
                            title={`blog-entry-${edge.node.slug}`}
                            to={`/blog/${edge.node.slug}`}
                          >
                            <div
                              className={homeStyles.blogImage}
                              style={{ backgroundImage: `url(${image})` }}
                            ></div>
                            <div
                              className={homeStyles.textContainer}
                              style={{ marginLeft: movePosition }}
                            >
                              <h2
                                dangerouslySetInnerHTML={{
                                  __html:
                                    edge.node.title.length > 50
                                      ? edge.node.title.slice(0, 50) + "..."
                                      : edge.node.title,
                                }}
                                className={homeStyles.blogTitle}
                              ></h2>
                              <p className={homeStyles.blogDate}>
                                {edge.node.date}
                              </p>
                            </div>
                          </Link>
                        </li>
                      )
                    })
                  : data.allWordpressPost.edges.map((edge, i) => {
                      const image = edge.node.featured_media
                        ? edge.node.featured_media.source_url
                        : "https://www.uniomx.com/static/ni%C3%B1os-4db7bcedfa4f813de3752dcf15e04716.png"
                      return i % 2 > 0 ? (
                        <li className={homeStyles.post}>
                          <Link
                            className={homeStyles.link}
                            title={`blog-entry-${edge.node.slug}`}
                            to={`/blog/${edge.node.slug}`}
                          >
                            <div
                              className={homeStyles.textContainer}
                              style={{ marginRight: movePosition }}
                            >
                              <h2
                                dangerouslySetInnerHTML={{
                                  __html:
                                    edge.node.title.length > 50
                                      ? edge.node.title.slice(0, 50) + "..."
                                      : edge.node.title,
                                }}
                                className={homeStyles.blogTitle}
                              ></h2>
                              <p className={homeStyles.blogDate}>
                                {edge.node.date}
                              </p>
                            </div>
                            <div
                              className={homeStyles.blogImage}
                              style={{ backgroundImage: `url(${image})` }}
                            ></div>
                          </Link>
                        </li>
                      ) : (
                        <li className={homeStyles.post}>
                          <Link
                            className={homeStyles.link}
                            title={`blog-entry-${edge.node.slug}`}
                            to={`/blog/${edge.node.slug}`}
                          >
                            <div
                              className={homeStyles.blogImage}
                              style={{ backgroundImage: `url(${image})` }}
                            ></div>
                            <div
                              className={homeStyles.textContainer}
                              style={{ marginLeft: movePosition }}
                            >
                              <h2
                                dangerouslySetInnerHTML={{
                                  __html:
                                    edge.node.title.length > 50
                                      ? edge.node.title.slice(0, 50) + "..."
                                      : edge.node.title,
                                }}
                                className={homeStyles.blogTitle}
                              ></h2>
                              <p className={homeStyles.blogDate}>
                                {edge.node.date}
                              </p>
                            </div>
                          </Link>
                        </li>
                      )
                    })}
              </ol>
              {numPages > 1 ? (
                <div className={homeStyles.buttonContainer}>
                  {currentPage > 1 ? (
                    currentPage === 2 ? (
                      <Link to="/" className={homeStyles.carouselButton}>
                        {"<"}
                      </Link>
                    ) : (
                      <Link
                        to={`/${currentPage - 1}`}
                        className={homeStyles.carouselButton}
                      >
                        {"<"}
                      </Link>
                    )
                  ) : (
                    ""
                  )}
                  {Array.from({ length: numPages }, (_, i) =>
                    i === 0 ? (
                      <Link
                        activeStyle={{ textDecoration: "underline" }}
                        className={homeStyles.carouselButton}
                        to="/"
                      >
                        {i + 1}
                      </Link>
                    ) : (
                      <Link
                        activeStyle={{ textDecoration: "underline" }}
                        className={homeStyles.carouselButton}
                        to={`/${i + 1}`}
                      >
                        {i + 1}
                      </Link>
                    )
                  )}
                  {currentPage === numPages ? (
                    ""
                  ) : (
                    <Link
                      to={`/${currentPage + 1}`}
                      className={homeStyles.carouselButton}
                    >
                      {">"}
                    </Link>
                  )}
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Home
