import React, { useRef, useEffect } from "react"
import TextField from "@material-ui/core/TextField"
import ClearIcon from "@material-ui/icons/Clear"
import Paper from "@material-ui/core/Paper"
import IconButton from "@material-ui/core/IconButton"
import SearchIcon from "@material-ui/icons/Search"
import searchStyles from "./search.module.scss"
import { useAllWordpressPosts } from "./useAllWordpressPosts"

const SearchBlogPosts = ({
  setSearchResults,
  search,
  setSearch,
  fireSubmit,
  setFireSubmit,
}) => {
  const posts = useAllWordpressPosts()
  const inputEl = useRef()
 

  let results = []
  function filterByContent(obj) {
    const postToFilterByTitle = obj.node.title
      .split(" ")
      .filter(word => word.toLowerCase() === search)
    const postToFilterByContent = obj.node.content
      .split(" ")
      .filter(word => word.replace("<p>", "").toLowerCase() === search)
    const postToFilterByTags =
      obj.node.tags.length > 0
        ? obj.node.tags.filter(word => word.name === search)
        : ""
    let rawResults = []
    if (postToFilterByTitle.length > 0) {
      rawResults.push(obj)
    }
    if (postToFilterByContent.length > 0) {
      if (!rawResults.includes(obj)) {
        rawResults.push(obj)
      }
    }
    if (postToFilterByTags.length > 0) {
      if (!rawResults.includes(obj)) {
        rawResults.push(obj)
      }
    }
    results = results.concat(rawResults)
  }
  const clearSearchInput = () => {
    setFireSubmit(false)
    setSearchResults([])
    setSearch("")
    inputEl.current.focus()
  }

  function beginFiltering() {
    setFireSubmit(false)
    posts.filter(filterByContent)
    setSearchResults(results)
    if (results.length === 0) {
      alert("No hay entradas!")
      setSearchResults([])
      setSearch("")
    }
  }
  const handleSubmit = event => {
    event.preventDefault()
    beginFiltering()
  }
  useEffect(() => {
    if (fireSubmit) {
      beginFiltering()
    }
  }, [fireSubmit])

  return (
    <form
      className={searchStyles.searchFormContainer}
      onSubmit={event => handleSubmit(event)}
    >
      <Paper className={searchStyles.search} elevation={1}>
        <IconButton onClick={clearSearchInput}>
          <ClearIcon />
        </IconButton>
        <TextField
          fullWidth
          placeholder="Busca el tema de tu interés"
          className={searchStyles.searchInput}
          InputProps={{
            disableUnderline: true,
          }}
          onChange={event => setSearch(event.target.value.toLowerCase())}
          value={search}
          inputRef={inputEl}
        />
        <IconButton type="submit">
          <SearchIcon />
        </IconButton>
      </Paper>
    </form>
  )
}

export default SearchBlogPosts
