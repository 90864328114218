import { useStaticQuery, graphql } from "gatsby"

export const useAllWordpressPosts = () => {
  const data = useStaticQuery(graphql`
    query {
      allWordpressPost {
        edges {
          node {
            tags {
              name
            }
            slug
            content
            title
            date(formatString: "DD MMMM YYYY", locale: "es")
            featured_media {
              title
              source_url
            }
          }
        }
      }
    }
  `)
  return data.allWordpressPost.edges
}
